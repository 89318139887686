import React, { useState } from "react";
import {
  GlobeAltIcon,
  MapIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";

export default function BusinessCardProfile({
  name = "John Doe",
  title = "Title",
  company = "Company Name",
  tagline = "Tagline goes here...",
  biography = "Bio goes here...",
  website = "Website.com",
  address = "Address goes here...",
  profileImage = "/placeholder.svg?height=200&width=200",
  wallpaperImage = "/placeholder.svg?height=300&width=1000",
  logoImage = "/placeholder.svg?height=50&width=50",
  accentColor = "#3498db",
  backgroundColor = "#f0f0f0",
  primaryColor = "#333333",
  secondaryColor = "#666666",
  onEvent = (event) => {},
}) {
  const [isBioExpanded, setIsBioExpanded] = useState(false);

  const toggleBio = () => {
    setIsBioExpanded(!isBioExpanded);
  };

  return (
    <div
      className="max-w-2xl mx-auto p-0 rounded-lg mt-1 shadow-2xl"
      style={{
        "--accent-color": accentColor,
        "--background-color": backgroundColor,
        "--primary-color": primaryColor,
        "--secondary-color": secondaryColor,
        backgroundColor: "var(--background-color)",
      }}
    >
      <div className="relative mb-12">
        <div className="relative">
          <img
            src={wallpaperImage}
            alt="Wallpaper"
            width={1000}
            height={216}
            className="w-full h-32 object-contain rounded-t-lg bg-slate-50 border-b-slate-300"
          />
          <div className="absolute inset-0 bg-black opacity-30 rounded-t-lg"></div>
        </div>
        <img
          src={profileImage ? profileImage : "/placeholder.jpg"}
          alt={name}
          width={144}
          height={144}
          className="absolute left-1/2 transform -translate-x-1/2 -bottom-12 w-28 h-28 rounded-full border-4 object-cover bg-slate-50"
          style={{ borderColor: "var(--accent-color)" }}
        />
      </div>
      <div className="p-4">
        <div className="text-center mb-6">
          <h1
            className="text-2xl font-bold "
            style={{ color: "var(--primary-color)" }}
          >
            {name}
          </h1>
          <p className="text-lg" style={{ color: "var(--secondary-color)" }}>
            {title}
          </p>
        </div>

        <div className="flex flex-col items-center gap-2 mb-6">
          <div className="flex items-center justify-center">
            <img
              src={logoImage}
              alt={company}
              height={50}
              className="w-12 h-12 mr-4 rounded-lg object-contain"
            />
            <div>
              <h2
                className="text-xl font-semibold"
                style={{ color: "var(--primary-color)" }}
              >
                {company}
              </h2>
              <p className="italic" style={{ color: "var(--secondary-color)" }}>
                {tagline}
              </p>
            </div>
          </div>
          {!isBioExpanded && (
            <button
              className="ml-4 flex gap-2 items-center"
              onClick={toggleBio}
              style={{ color: "var(--accent-color)" }}
            >
              <InformationCircleIcon
                className=" w-8"
                style={{ color: "var(--accent-color)" }}
              />{" "}
              See more
            </button>
          )}
        </div>
        <AnimatePresence initial={false}>
          {isBioExpanded && (
            <motion.div
              key="content"
              initial="collapsed"
              animate="expanded"
              exit="collapsed"
              variants={{
                expanded: { opacity: 1, height: "auto" },
                collapsed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              style={{ transformOrigin: "top" }}
            >
              <motion.div
                variants={{
                  expanded: { rotateX: 0 },
                  collapsed: { rotateX: -90 },
                }}
                transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
              >
                {" "}
                <div className="mb-6 mt-6">
                  <h3
                    className="text-lg font-semibold mb-2"
                    style={{ color: "var(--primary-color)" }}
                  >
                    About {company}
                  </h3>
                  <div className="flex gap-2 ">
                    <p style={{ color: "var(--secondary-color)" }}>
                      {biography}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center">
                  <div className="flex items-center mb-2 sm:mb-0">
                    <GlobeAltIcon
                      className="mr-2 w-6"
                      style={{ color: "var(--accent-color)" }}
                    />
                    <a
                      href={`${website}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                      style={{ color: "var(--accent-color)" }}
                      onClick={() =>
                        onEvent({
                          action_type: "visit_website",
                          target: "company_website",
                        })
                      }
                    >
                      {website}
                    </a>
                  </div>
                  <div className="flex items-center">
                    <MapIcon
                      className="mr-2 w-6"
                      style={{ color: "var(--accent-color)" }}
                    />
                    <span style={{ color: "var(--secondary-color)" }}>
                      {address}
                    </span>
                  </div>
                </div>
                <div className="flex items-end justify-end">
                  <button
                    variant="link"
                    onClick={toggleBio}
                    className="font-normal text-sm mt-4"
                    style={{ color: "var(--accent-color)" }}
                  >
                    See less
                  </button>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
}
